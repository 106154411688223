import pdf from './pdf.png';

export default `
<div class="animated fadeIn">
<h2>About Me</h2>
<table width='100%' height='1'>
	<tr>
		<th><img src='img/me.jpg' alt='' width='300' height='auto' class='alignleft' /></th>
		<td> 
			안녕하세요.
			<br><br>
			3년차 백엔드 개발자 이석주입니다. 
			<br><br>
			방문해주셔서 감사합니다!
			<br><br><br>
			Phone: 010-4053-2175
			<br><br>
			Email: as217512@gmail.com
			<br><br>
			
			GitHub: <a href="https://github.com/a2175" target="_blank">https://github.com/a2175</a>
		</td>
	</tr>
</table>
<br>
<br>
<br>
<br>

<h2>Experience</h2>
<table class='test' align='center'>
	<tr>
		<td><img src="img/php.png" /></td>
		<td><img src='img/nodejs.png' /></td>
		<td><img src='img/java.jpg' /></td>
		<td><img src='img/CnCPP.jpg' /></td>
	</tr>
	<tr>
		<td><img src="img/html_css.png" /></td>
		<td><img src='img/JS.png' /></td>
		<td><img src='img/jquery.png' /></td>
		<td><img src='img/bootstrap.png' /></td>
	</tr>
	<tr>
		<td><img src='img/codeigniter.webp' /></td>
		<td><img src='img/spring.png' /></td>
		<td><img src='img/react.png' /></td>
		<td><img src='img/redis.png' /></td>
	</tr>
	<tr>
		<td><img src='img/nginx.png' /></td>
		<td><img src='img/aws.png' /></td>
		<td><img src='img/firebase.png' /></td>
		<td><img src='img/mysql.jpg' /></td>
	</tr>
</table>

<br>
<br>

<h2>Career</h2>

<dl>
	<dt><b>1) 프로젝트명: 단어 퍼즐 게임</b></dt>
	<dt>연계/소속회사 : 그레이프게이밍</dt>
	<dt>주요 업무 : 백엔드 담당</dt>
	<dt>담당 역할 : API 설계 및 개발, DB 설계 및 개발, 관리자 페이지 개발, AWS EC2 운영 및 관리</dt>
	<dt>기술 스택 : CentOS7, PHP, CodeIgniter, MYSQL, Redis, AWS</dt>
	<dt>업무 기간 : 2023.03 ~ 2023.10 (약 7개월)</dt>
	<dt>개발 인원 : 4명</dt>
	<dt>상세 내용 :</dt>
</dl>
<ul>
	<li>언어 공부를 목적으로 하는 학습 게임</li>
	<li>PHP Codeigniter, MYSQL을 사용해 개발</li>
	<li>API 설계 및 개발</li>
		<dd>ㄴ Redis를 사용해 캐싱 처리 최적화 및 실시간 랭킹 구현</dd>
		<dd>ㄴ 구글, 애플, 페이스북 소셜 로그인 기능</dd>
		<dd>ㄴ 각 스토어 결제 검증</dd>
	<li>DB 설계 및 개발</li>
		<dd>ㄴ 이벤트, 프로시저를 작성해 KPI 데이터를 생성 및 관리</dd>
	<li>관리자 페이지 개발</li>
		<dd>ㄴ 게임 컨텐츠 관리 기능</dd>
		<dd>ㄴ KPI 데이터 모니터링</dd>
		<dd>ㄴ FCM PUSH 알람 전송 기능</dd>
</ul>


<br>

<dl>
	<dt><b>2) 프로젝트명: 오렌지 바나나 펀치</b></dt>
</dl>

<img src="img/obpunch.png" />

<dl>
	<dt>연계/소속회사 : 그레이프게이밍</dt>
	<dt>주요 업무 : 백엔드 담당</dt>
	<dt>담당 역할 : API 설계 및 개발, TCP 서버 개발, DB 설계 및 개발, 관리자 페이지 개발, AWS EC2 운영 및 관리</dt>
	<dt>기술 스택 : CentOS7, PHP, CodeIgniter, Node.js, MYSQL, Redis, AWS</dt>
	<dt>업무 기간 : 2021.04 ~ 2023.03 (약 23개월)</dt>
	<dt>개발 인원 : 4명</dt>
	<dt>상세 내용 :</dt>
</dl>
<ul>
	<li>실시간 대전 PvP 퍼즐 게임</li>
	<li>플레이 스토어, 앱 스토어, 원 스토어, 갤럭시 스토어에 출시 및 라이브 서비스</li>
	<li>PHP, Codeigniter, Node.js, MYSQL을 사용해 개발</li>
	<li>API 설계 및 개발</li>
		<dd>ㄴ Redis를 사용해 캐싱 처리 최적화 및 실시간 랭킹 구현</dd>
		<dd>ㄴ 구글, 애플, 페이스북 소셜 로그인 기능</dd>
		<dd>ㄴ 각 스토어 결제 검증</dd>
	<li>TCP 서버 개발</li>
		<dd>ㄴ Node.js를 사용해 실시간 PvP 대전 TCP 서버 개발</dd>
	<li>DB 설계 및 개발</li>
		<dd>ㄴ 이벤트, 프로시저를 작성해 KPI 데이터를 생성 및 관리</dd>
	<li>관리자 페이지 개발</li>
		<dd>ㄴ 게임 컨텐츠 관리 기능</dd>
		<dd>ㄴ KPI 데이터 모니터링</dd>
		<dd>ㄴ FCM PUSH 알람 전송 기능</dd>
</ul>

</div>
`;
